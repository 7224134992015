import React from "react";
import RateSheet from "../rate-sheet/rate-sheet.component";
import Layout from "../layout";
import ContactUs from "../contact-us/contact-us.component";
import "../../styles/app.scss";

export default function NewsArticle({ children, title, description, isNew }) {
  return (
    <Layout title={title} metaDescription={description}>
      <section className="news-article page-section row">
        <div className="news-article__body col-xs-12 col-lg-7">{children}</div>
        <div className="news-article__sidebar col-xs-12 col-lg-4 col-lg-offset-1">
          <ContactUs />
          {
            isNew ? <RateSheet /> : null
          }
        </div>
      </section>
    </Layout>
  );
}
