import React, { useContext } from "react";
import { Link } from "gatsby";
import { triggerTealiumEvent } from "../../utils/tealiumUtils";
import { useLocation } from "@reach/router";
import { MenuContext } from "../../context";

const GeneralAnchor = ({
  children,
  isBlank,
  href,
  isLink = false,
  target,
  ariaLabel = "",
  referrer,
  isRed,
  downloadTitle,
  onClick,
  additionalClass,
  noClass = false,
}) => {
  const menuContext = useContext(MenuContext);

  const location = useLocation();
  const handleClick = (e) => {
    if (typeof window !== "undefined" && window.utag) {
      if (location.pathname === "/resources" || location.pathname === "/resources/") {

        const fileName = downloadTitle || href.split("/").pop() || "Unknown File";

        triggerTealiumEvent({
          tealiumEvent: "link",
          pageUrl: location.href,
          eventCategory: "content",
          eventAction: "download",
          eventContent: `download: ${fileName}`,
          pageType: "product information",
          pageName: "resources",
          pageSubCategory: "resources",
          rawDataLayer: "10451v51",
        });
      }
      
      if (onClick) {
        onClick(e);
      }
    }
  };

  return (
    <>
      {isLink === true ? (
        <a
          className={`${(additionalClass && additionalClass) || ""}${
            (noClass !== true && " general-anchor-btn") || ""
          }${(isRed && " general-anchor-btn--red") || ""}`}
          href={href}
          target={target ? target : isBlank ? "_blank" : "_self"}
          rel={referrer ? referrer : undefined}
          download={downloadTitle}
          aria-label={ariaLabel}
          onClick={handleClick}
          inert={menuContext.isMenuOpen ? "true" : null}
        >
          {children}
        </a>
      ) : (
        <Link
          className={`${(additionalClass && additionalClass) || ""}${
            (noClass !== true && " general-anchor-btn") || ""
          }${(isRed && " general-anchor-btn--red") || ""}`}
          to={href}
          target={target ? target : isBlank ? "_blank" : "_self"}
          rel={referrer ? referrer : undefined}
          download={downloadTitle}
          aria-label={ariaLabel}
          onClick={handleClick}
          inert={menuContext.isMenuOpen ? "true" : null}
        >
          {children}
        </Link>
      )}
    </>
  );
};

export default GeneralAnchor;
