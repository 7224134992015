import React from "react";

const RateSheet = ({href, date}) => {
  return (
    <section className="rate-sheet">
      <p>Historic rates displayed on the below pdf are for information only, therefore any associated product codes will not be active on the broker platform.</p>
      <p>
        <a title="This PDF opens in a new window" target="_blank" href={href}>
          Rate sheet {date}.
        </a>
      </p>
    </section>
  );
};

export default RateSheet;
