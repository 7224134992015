import React, { useContext } from 'react';
import { Link } from 'gatsby';
import GeneralAnchor from '../general-anchor/general-anchor.component';
import { MenuContext } from '../../context';

import ContactSvg from '../../assets/contact.inline.svg';
import PhoneSvg from '../../assets/phone.inline.svg';

const ContactUs = () => {
  const menuContext = useContext(MenuContext);
  return (
    <section className='contact-us'>
      <h2>Contact us</h2>
      <GeneralAnchor
        isBlank
        href='/broker-support'
        rel='noreferrer'
        isLink={false}
        noClass={true}
        inert={menuContext.isMenuOpen ? 'true' : null}
      >
        <PhoneSvg/>
        Call our help desk
      </GeneralAnchor>
      <Link to='/broker-support' inert={menuContext.isMenuOpen ? 'true' : null}>
        <ContactSvg/>
        Find a BDM
      </Link>
    </section>
  );
};

export default ContactUs;