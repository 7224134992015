import React from 'react';

import NewsArticle from '../../../components/news-article/news-article.component'

import { LATEST_RATE_SHEET } from '../../../data/latest-rate-sheet.data';

const Article = () => (
    <NewsArticle>
      <h1 className='page-title'>Changes to our mortgage products</h1>
      <p>With effect from Tuesday 10 May 2022 we will be making the following changes to our range</p>
      <p><strong>Summary of changes</strong></p>
      <p><strong>Residential Existing Customer Switching Range</strong></p>
      <ul>
        <li>2 Year Fixed Standard at 60%, 70%, 75%, 80%, 85%, 90% and 95% LTV have increased</li>
        <li>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85%, 90% and 95% LTV have increased</li>
        <li>3 Year Fixed Standard at 60%, 80%, 85%, 90% and 95% LTV have increased</li>
        <li>3 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85%, 90% and 95% LTV have increased</li>
        <li>5 Year Fixed Standard at 60%, 80%, 85%, 90% and 95% LTV have increased</li>
        <li>5 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85%, 90% and 95% LTV have increased</li>
        <li>5 Year Fixed Premier Exclusive at 60%, 80%, 85% and 90% LTV have increased</li>
      </ul>
      <p><strong>Residential Existing Customer Borrowing More Range</strong></p>
      <ul>
        <li>2 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
        <li>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
        <li>3 Year Fixed Standard at 60%, 80%, 85% and 90% LTV have increased</li>
        <li>3 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
        <li>5 Year Fixed Standard at 60%, 80%, 85% and 90% LTV have increased</li>
        <li>5 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
        <li>5 Year Fixed Premier Exclusive at 60%, 80%, 85% and 90% LTV have increased</li>
      </ul>
      <p><strong>Residential Remortgage Range</strong></p>
      <ul>
        <li>2 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
        <li>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
        <li>3 Year Fixed Standard at 60%, 70%, 75%, 80% and 85% LTV have increased</li>
        <li>3 Year Fixed Fee Saver at 70% and 75% LTV have increased</li>
        <li>5 Year Fixed Standard at 60%, 70%, 75%, 80% and 85% LTV have increased</li>
        <li>5 Year Fixed Fee Saver at 70% and 75% LTV have increased</li>
        <li>5 Year Fixed Premier Exclusive at 60%, 70%, 75%, 80% and 85% LTV have increased</li>
      </ul>
      <p><strong>Residential First Time Buyer Range</strong></p>
      <ul>
        <li>2 Year Fixed Standard at 60%, 70%, 75%, 80%, 85%, 90% and 95% LTV have increased</li>
        <li>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85%, 90% and 95% LTV have increased</li>
        <li>3 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
        <li>3 Year Fixed Fee Saver at 70%, 75%, 80%, 85% and 90% LTV have increased</li>
        <li>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
        <li>5 Year Fixed Fee Saver at 70%, 75%, 80%, 85% and 90% LTV have increased</li>
        <li>5 Year Fixed Premier Exclusive at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      </ul>
      <p><strong>Residential Home Mover Range</strong></p>
      <ul>
        <li>2 Year Fixed Standard at 60%, 70%, 75%, 80%, 85%, 90% and 95% LTV have increased</li>
        <li>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85%, 90% and 95% LTV have increased</li>
        <li>3 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
        <li>3 Year Fixed Fee Saver at 70%, 75%, 80%, 85% and 90% LTV have increased</li>
        <li>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
        <li>5 Year Fixed Fee Saver at 70%, 75%, 80%, 85% and 90% LTV have increased</li>
        <li>5 Year Fixed Premier Exclusive at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      </ul>
      <p><strong>UK Buy to Let Existing Customer Switching Range</strong></p>
      <ul>
        <li>2 Year Fixed Standard at 60%, 65% and 75% LTV have increased</li>
        <li>2 Year Fixed Fee Saver at 60%, 65% and 75% LTV have increased</li>
        <li>5 Year Fixed Standard at 60%, 65% and 75% LTV have increased</li>
        <li>5 Year Fixed Fee Saver at 60%, 65% and 75% LTV have increased</li>
      </ul>
      <p><strong>UK Buy to Let Existing Customer Borrowing More Range</strong></p>
      <ul>
        <li>2 Year Fixed Standard at 60%, 65% and 75% LTV have increased</li>
        <li>2 Year Fixed Fee Saver at 60%, 65% and 75% LTV have increased</li>
        <li>5 Year Fixed Standard at 60%, 65% and 75% LTV have increased</li>
        <li>5 Year Fixed Fee Saver at 60%, 65% and 75% LTV have increased</li>
      </ul>
      <p><strong>UK Buy to Let Remortgage Range</strong></p>
      <ul>
        <li>2 Year Fixed Standard at 60%, 65% and 75% LTV have increased</li>
        <li>2 Year Fixed Fee Saver at 60%, 65% and 75% LTV have increased</li>
        <li>5 Year Fixed Standard at 60%, 65% and 75% LTV have increased</li>
        <li>5 Year Fixed Fee Saver at 60%, 65% and 75% LTV have increased</li>
      </ul>
      <p><strong>UK Buy to Let Purchase Range</strong></p>
      <ul>
        <li>2 Year Fixed Standard at 60%, 65% and 75% LTV have increased</li>
        <li>2 Year Fixed Fee Saver at 60%, 65% and 75% LTV have increased</li>
        <li>5 Year Fixed Standard at 60%, 65% and 75% LTV have increased</li>
        <li>5 Year Fixed Fee Saver at 60%, 65% and 75% LTV have increased</li>
      </ul>
      <p><a title="This PDF opens in a new window" target="_blank" href={LATEST_RATE_SHEET.href}>Link to our latest rate sheet</a></p>
    </NewsArticle>
)

export default Article